import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { AuthService } from '../../auth/store/auth.service';
import { MsalService } from '@azure/msal-angular';

export const LoginGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
  
) => {
  const router = inject(Router);
  const authService = inject(AuthService);
  const masalService = inject(MsalService);

  const jwt = authService.getToken();
  console.log(masalService.instance.getActiveAccount());
  console.log(masalService.instance.getAllAccounts());
  
  if (jwt && masalService.instance.getActiveAccount()){
    console.log('in Login Gurad....');
    router.navigate(['/main/home/dashboard']);
    return false;
  } 
  return true;
};
