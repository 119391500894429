<div class="layout-topbar">
  <a class="layout-topbar-logo" routerLink="/main/home/dashboard">
    <!-- <img src="assets/layout/images/{{layoutService.config().colorScheme === 'light' ? 'logo-dark' : 'logo-white'}}.svg" alt="logo"> -->
    <img src="assets/images/logo.png" alt="logo"> 
    <!-- <span>JWIL</span> -->
  </a>

  <button #menubutton class="p-link layout-menu-button layout-topbar-button" (click)="layoutService.onMenuToggle()">
    <i class="pi pi-bars"></i>
  </button>

  <button #topbarmenubutton class="p-link layout-topbar-menu-button layout-topbar-button"
    (click)="layoutService.showProfileSidebar()">
    <i class="pi pi-ellipsis-v"></i>
  </button>

  <div #topbarmenu class="layout-topbar-menu"
    [ngClass]="{'layout-topbar-menu-mobile-active': layoutService.state.profileSidebarVisible}">
    <button class="p-link layout-topbar-button ProfileButton">
      <i class="pi pi-user"></i>
      <font>{{userName}} </font>
      <!-- <span>Profile</span> -->
    </button>
    <button class="p-link layout-topbar-button" (click)="logout()">
      <i class="pi pi-sign-out"></i>
      <span>Logout</span>
    </button>
  </div>
</div>