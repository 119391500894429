import { Location } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit, inject } from '@angular/core';
import { Router, RouterOutlet } from '@angular/router';
import {
  MSAL_GUARD_CONFIG,
  MsalGuardConfiguration,
  MsalService,
} from '@azure/msal-angular';
import {
  AuthenticationResult,
  InteractionType,
  PopupRequest,
  RedirectRequest,
} from '@azure/msal-browser';
import { ToastModule } from 'primeng/toast';
import { Subject } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
// import { MsalServiceV } from './shared/msal/msal.service';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { AuthService } from './auth/store/auth.service';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, ToastModule, ConfirmDialogModule],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'Microsoft identity platform';
  loginDisplay = false;
  isIframe = false;

  private readonly _destroying$ = new Subject<void>();
  redirectHash: string | undefined;

  router = inject(Router);
  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private authService: MsalService,
    private authServiceSys: AuthService,
    private location: Location
  ) {}

  ngOnInit(): void {
    const hash = this.location.path(true).split('#').pop();
    if (hash) {
      this.redirectHash = `#${hash}`;
    }
    // this.authService.initialize().subscribe((x) => {
    //   this.authService.loginRedirect();
    // });

    console.log('this.redirectHash-->> ', this.redirectHash);
    if(this.redirectHash === undefined){
      // this.authService.initialize().subscribe();
      this.redirectHash = '/login'
    }
    this.authService
      .handleRedirectObservable(this.redirectHash)
      .pipe(
        map((result: AuthenticationResult) => {
          if (result) {
            const name = result?.account?.name || '';
            localStorage.setItem('userName', name)!;
            this.getToken(result);
            this.authService.instance.setActiveAccount(result.account);
          }
          return result;
        }),
        switchMap((res: AuthenticationResult) =>
          this.fetchPermissionOfUser().pipe(
            map((permissionData) => {
              if (permissionData?.data !== null) {
                localStorage.setItem(
                  'USER_PERMISSION',
                  JSON.stringify(permissionData?.data)
                );
              } else {
                localStorage.setItem('USER_PERMISSION', '');
              }
              if (res) {
                setTimeout(
                  () => this.router.navigate(['/main/home/dashboard']),
                  300
                );
              }
            })
          )
        )
      )
      .subscribe();
    
    
  }
  fetchPermissionOfUser(userNameId?: string) {
    return this.authServiceSys.getPermissionOfUser(userNameId);
  }

  getToken(result: AuthenticationResult) {
    const data = {
      emailId: result.account.username,
      tokenType: 'Office365',
      token: result.accessToken,
      homeAccountId: result.account.homeAccountId,
      environment: result.account.environment,
    };
    localStorage.setItem('MSALDATA', JSON.stringify(data));
    this.setToken(data);
  }
  setToken(data: any) {
    localStorage.setItem('O365', JSON.stringify(data));
  }

  setLoginDisplay() {
    this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
  }

  checkAndSetActiveAccount() {
    /**
     * If no active account set but there are accounts signed in, sets first account to active account
     * To use active account set here, subscribe to inProgress$ first in your component
     * Note: Basic usage demonstrated. Your app may require more complicated account selection logic
     */
    let activeAccount = this.authService.instance.getActiveAccount();

    if (
      !activeAccount &&
      this.authService.instance.getAllAccounts().length > 0
    ) {
      let accounts = this.authService.instance.getAllAccounts();
      // add your code for handling multiple accounts here
      this.authService.instance.setActiveAccount(accounts[0]);
    }
  }

  login() {
    if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
      if (this.msalGuardConfig.authRequest) {
        this.authService
          .loginPopup({
            ...this.msalGuardConfig.authRequest,
          } as PopupRequest)
          .subscribe((response: AuthenticationResult) => {
            this.authService.instance.setActiveAccount(response.account);
            console.log(response);
          });
      } else {
        this.authService
          .loginPopup()
          .subscribe((response: AuthenticationResult) => {
            this.authService.instance.setActiveAccount(response.account);
            console.log(response);
          });
      }
    } else {
      if (this.msalGuardConfig.authRequest) {
        this.authService.loginRedirect({
          ...this.msalGuardConfig.authRequest,
        } as RedirectRequest);
      } else {
        this.authService.loginRedirect();
      }
    }
  }

  logout() {
    if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
      this.authService.logoutPopup({
        account: this.authService.instance.getActiveAccount(),
      });
    } else {
      this.authService.logoutRedirect({
        account: this.authService.instance.getActiveAccount(),
      });
    }
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }
}
